function createDevice() {
  const form = document.getElementById("create-form");
  if (!form) {
    throw new Error("Didn't find #create-form");
  }
  if (!navigator.usb) {
    form.innerHTML =
      '<p>This browser doesn\'t support WebUSB.</p><p><a href="https://caniuse.com/webusb" class="white">Please try again with a browser that supports WebUSB.</p>';
    return;
  }
  navigator.usb
    .requestDevice({
      filters: [
        { vendorId: 0x209a, productId: 0x010a },
        { vendorId: 0x0e8d, productId: 0x082c },
        { vendorId: 0x0e8d, productId: 0x0811 },
        { vendorId: 0x0a12, productId: 0x0061 },
      ],
    })
    .then((device) => {
      document
        .getElementsByName("manufacturer")
        .forEach((manufacturerElement) => {
          manufacturerElement.value = device.manufacturerName;
        });
      document
        .getElementsByName("product_name")
        .forEach((productNameElement) => {
          productNameElement.value = device.productName;
        });
      document
        .getElementsByName("serial_number")
        .forEach((serialNumberElement) => {
          serialNumberElement.value = device.serialNumber;
        });
      document.getElementsByName("vendor_id").forEach((vendorIdElement) => {
        vendorIdElement.value = device.vendorId;
      });
      document.getElementsByName("product_id").forEach((productIdElement) => {
        productIdElement.value = device.productId;
      });
      form.submit();
    })
    .catch((error) => {
      console.error(error);
      location.reload();
    });
}

document.addEventListener("claude:createDevice", createDevice);
